import React from 'react';

// import FontAwesomeIcon from '@fortawesome/react-fontawesome';
// import faExclamationCircle from '@fortawesome/fontawesome-free-solid/faExclamationCircle';

const ResturantClosedPage = () => {
    return (
        <div className="container">
            <div className="not_found_container">
                {/* <FontAwesomeIcon icon={faExclamationCircle}/> */}
                <h2>
                    Restaurant is currently closed. Please check back during business hours.
                </h2>
            </div>
        </div>
    );
};

export default ResturantClosedPage;